import {
    IconBuildingBank,
} from '@tabler/icons';

const memberPages = {
    id: 'pages',
    title: '',
    type: 'group',
    breadcrumbs: false,
    children: [
        {
            id: 'leadManagement',
            title: ' Lead Management',
            type: 'collapse',
            icon: IconBuildingBank,
            children: [
                {
                    id: 'leads',
                    title: 'Leads',
                    type: 'item',
                    url: '/leadManagement/leads',
                    breadcrumbs: false
                },
            ]
        },
    ]
};

export default memberPages;
