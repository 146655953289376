import {
    GET_PUSH_NOTIFICATIONS_SUCCESS,
    GET_PUSH_NOTIFICATIONS_FAIL,
    GET_PUSH_NOTIFICATIONS_START
} from 'store/actions';



const initialState = {
    pushNotificationLoading: false,
    pushNotifications: [],
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PUSH_NOTIFICATIONS_START:
            return {
                ...state,
                pushNotificationLoading: true
            };
        case GET_PUSH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                pushNotificationLoading: false,
                pushNotifications: action.data,
            };
        case GET_PUSH_NOTIFICATIONS_FAIL:
            return {
                ...state,
                pushNotificationLoading: false,
                pushNotifications: [],
            };
        default:
            return state;
    }
};
export default notificationReducer;
