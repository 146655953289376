// assets
import { IconNotes, IconMessage2 } from '@tabler/icons';


const memberPages = {
    id: 'pages',
    title: '',
    type: 'group',
    breadcrumbs: false,
    children: [

        {
            id: 'posts',
            title: 'Posts',
            type: 'collapse',
            icon: IconNotes,
            children: [
                {
                    id: 'pendingPost',
                    title: 'Pending',
                    type: 'item',
                    url: '/posts/pending',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'discussions',
            title: 'Discussions',
            type: 'collapse',
            icon: IconMessage2,
            children: [
                {
                    id: 'pendingDiscussion',
                    title: 'Pending',
                    type: 'item',
                    url: '/discussions/pending',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default memberPages;
