import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const Login = Loadable(lazy(() => import('screens/authentication/authentication/Login')));
const EditLead = Loadable(lazy(() => import('screens/editLead')));
const UpsertUser = Loadable(lazy(() => import('screens/upsertUser')));

const Auth = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <Login />
        }, {
            path: '/leadManagement/lead/:leadID',
            element: <EditLead />
        },
        {
            path: '/users/upsert',
            element: <UpsertUser />
        },
        {
            path: '/users/upsert/:userID',
            element: <UpsertUser />
        },
    ]
};

export default Auth;
