import * as ACTIONS from 'store/actions';


const initialState = {
    getUsersLoading: false,
    getUserLoading: false,
    users: [],
    user: {},
    autoFollowUsersLoading: false,
    autoFollowUsers: [],
    adminLoading: false,
    admins: [],
    interalUsers: [],
    interalUsersLoading: false
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_USERS_START:
            return {
                ...state,
                getUsersLoading: true
            };
        case ACTIONS.GET_USERS_SUCCESS:
            return {
                ...state,
                getUsersLoading: false,
                users: action.data
            };
        case ACTIONS.GET_USERS_FAIL:
            return {
                ...state,
                getUsersLoading: false
            };
        case ACTIONS.GET_USER_START:
            return {
                ...state,
                getUserLoading: true
            };
        case ACTIONS.GET_USER_SUCCESS:
            return {
                ...state,
                getUserLoading: false,
                user: action.data
            };
        case ACTIONS.GET_USER_FAIL:
            return {
                ...state,
                getUserLoading: false
            };

        case ACTIONS.GET_USERS_FOR_AUTO_FOLLOW_START:
            return {
                ...state,
                autoFollowUsersLoading: true
            };
        case ACTIONS.GET_USERS_FOR_AUTO_FOLLOW_SUCCESS:
            return {
                ...state,
                autoFollowUsersLoading: false,
                autoFollowUsers: action.data
            };
        case ACTIONS.GET_USERS_FOR_AUTO_FOLLOW_FAIL:
            return {
                ...state,
                autoFollowUsersLoading: false
            };

        case ACTIONS.GET_ADMINS_START:
            return {
                ...state,
                adminLoading: true
            };
        case ACTIONS.GET_ADMINS_SUCCESS:
            return {
                ...state,
                adminLoading: false,
                admins: action.data
            };
        case ACTIONS.GET_ADMINS_FAIL:
            return {
                ...state,
                adminLoading: false
            };
        case ACTIONS.GET_INTERNAL_USERS_START:
            return {
                ...state,
                interalUsersLoading: true
            };
        case ACTIONS.GET_INTERNAL_USERS_SUCCESS:
            return {
                ...state,
                interalUsersLoading: false,
                interalUsers:
                    action.data[0]?.page > 1
                        ? [...state.interalUsers, ...action.data]
                        : action.data,
            };
        case ACTIONS.GET_INTERNAL_USERS_FAIL:
            return {
                ...state,
                interalUsersLoading: false
            };

        default:
            return state;
    }
};
export default userReducer;
