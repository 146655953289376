import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';

// utilities routing
const Users = Loadable(lazy(() => import('screens/users')));
const ReviewUsers = Loadable(lazy(() => import('screens/reviewUsers')));
const ReviewUsersTv = Loadable(lazy(() => import('screens/reviewUsersTv')));
const Userinsight = Loadable(lazy(() => import('screens/userInsight')));
const DiscussionsInsight = Loadable(lazy(() => import('screens/discussionInsight')));
const PushNotificationInsight = Loadable(lazy(() => import('screens/pushNotificationInsight')));
const PostInsight = Loadable(lazy(() => import('screens/postInsight')));
const Professions = Loadable(lazy(() => import('screens/professions')));
const Leads = Loadable(lazy(() => import('screens/leads')));
const PostTags = Loadable(lazy(() => import('screens/postTags')));
const ProfessionServices = Loadable(lazy(() => import('screens/professionServices')));
const ServiceLocationsAdmins = Loadable(lazy(() => import('screens/serviceLocations')));
const LocationZone = Loadable(lazy(() => import('screens/locationZone')));
const PostGrids = Loadable(lazy(() => import('screens/postGrids')));
const PostReviewGrids = Loadable(lazy(() => import('screens/postReviewGrid')));
const Posts = Loadable(lazy(() => import('screens/posts')));
const PostDesignInsight = Loadable(lazy(() => import('screens/postDesignInsight')));
const Disussion = Loadable(lazy(() => import('screens/discussions')));
const DisussionPosts = Loadable(lazy(() => import('screens/discussionPosts')));
const DisussionPostsReviewed = Loadable(lazy(() => import('screens/discussionPostsReviewed')));
const Push = Loadable(lazy(() => import('screens/push')));
const Admins = Loadable(lazy(() => import('screens/admins')));
const Chats = Loadable(lazy(() => import('screens/chats')));
const ReportedPosts = Loadable(lazy(() => import('screens/reportedPosts')));
const ReportedDiscussions = Loadable(lazy(() => import('screens/reportedDiscussions')));
const LeadMasterActivity = Loadable(lazy(() => import('screens/leadMasterActivity')));
const TvPosts = Loadable(lazy(() => import('screens/tvPosts')));
const MaterialInventory = Loadable(lazy(() => import('screens/materialInventory')));
const MaterialInventoryLeads = Loadable(lazy(() => import('screens/materialInventoryLeads')));
const Inquiries = Loadable(lazy(() => import('screens/inquiries')));
const DeepLink = Loadable(lazy(() => import('screens/deepLink')));
const Banner = Loadable(lazy(() => import('screens/banner')));
const ServiceProviders = Loadable(lazy(() => import('screens/serviceProviders')));
const TransactionServices = Loadable(lazy(() => import('screens/transactionServices')));


const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/push',
            element: <Push />
        },
        {
            path: '/admins',
            element: <Admins userType={3} />
        },
        {
            path: '/users',
            children: [
                {
                    path: 'review',
                    element: <ReviewUsers userType={3} />
                },
                {
                    path: 'reviewtv',
                    element: <ReviewUsersTv userType={3} />
                },
                {
                    path: 'all',
                    element: <Users userType={3} />
                },
                {
                    path: 'serviceproviders',
                    element: <ServiceProviders userType={3} />
                }
            ]
        },
        {
            path: '/chats',
            element: <Chats userType={3} />
        },
        {
            path: '/tvposts',
            element: <TvPosts userType={3} />
        },
        {
            path: '/inquiries',
            element: <Inquiries />
        },
        {
            path: '/posts',
            children: [
                {
                    path: 'pdInsight',
                    element: <PostDesignInsight />
                }, {
                    path: 'pending',
                    element: <Posts />
                },
                {
                    path: 'gPosts',
                    element: <PostGrids />
                },
                {
                    path: 'grPosts',
                    element: <PostReviewGrids />
                },
            ]
        },
        {
            path: '/discussions',
            children: [
                {
                    path: 'pending',
                    element: <Disussion />
                }, {
                    path: 'all',
                    element: <DisussionPosts />
                }, {
                    path: 'reviewed',
                    element: <DisussionPostsReviewed />
                }
            ]
        },
        {
            path: '/insight',
            children: [
                {
                    path: 'users',
                    element: <Userinsight />
                },
                {
                    path: 'posts',
                    element: <PostInsight />
                }, {
                    path: 'discussions',
                    element: <DiscussionsInsight />
                }, {
                    path: 'push',
                    element: <PushNotificationInsight />
                }

            ]
        },
        {
            path: '/reports',
            children: [
                {
                    path: 'posts',
                    element: <ReportedPosts />
                }, {
                    path: 'discussions',
                    element: <ReportedDiscussions />
                }
            ]
        },
        {
            path: '/utils',
            children: [
                {
                    path: 'lmActivity',
                    element: <LeadMasterActivity />
                },
                {
                    path: 'profession',
                    element: <Professions />
                },
                {
                    path: 'profession-services',
                    element: <ProfessionServices />
                },
                {
                    path: 'post-tags',
                    element: <PostTags />
                },
                {
                    path: 'service-locations',
                    element: <ServiceLocationsAdmins />
                },
                {
                    path: 'location-zones',
                    element: <LocationZone />
                },
                {
                    path: 'deepLinks',
                    element: <DeepLink />
                },
                {
                    path: 'banners',
                    element: <Banner />
                },
                {
                    path: 'transaction-service',
                    element: <TransactionServices />
                }
            ]
        },
        {
            path: '/leadManagement',
            children: [
                {
                    path: 'leads',
                    element: <Leads />
                },
            ]
        },
        {
            path: '/materialInventory',
            children: [
                {
                    path: 'items',
                    element: <MaterialInventory />
                },
                {
                    path: 'leads',
                    element: <MaterialInventoryLeads />
                },
            ]
        }
    ]
};

export default MainRoutes;
