import { all, fork } from 'redux-saga/effects';

import { watchLogin, watchLogout } from './authSaga';
import { watchGetPosts, watchVerifyPost, watchGetGridPosts, watchRejectPost, watchGetReviewedPosts, watchGetUserPosts } from './postSaga';
import { watchGetTvPosts, watchTvAddPost } from './tvPostSaga';
import {
    watchGetDiscussions, watchRejectDiscussion, watchVerifyDiscussionPost,
    watchGetDiscussionPosts,
    watchGetDiscussionComments,
    watchDiscussionComment,
    watchDiscussionReact,
    watchChangeDiscussionStatus,
    watchDiscussionReply,
    watchGetDiscussionReplies
} from './discussionSaga';
import {
    watchGetUsers,
    watchCreateUser,
    watchGetAdmins,
    watchResetAdminPassword,
    watchGetInternalUsers,
    watchGetUsersForAutoFollow,
    watchStartFollowService,
    watchGetUser,
    watchUpsertProfessional,
} from './userSaga';
import { watchSendFBNotification, watchGetPushNotifications, watchDeleteScheduleNotification, watchEditScheduleNotification } from './notificationSaga';
import {
    watchAddProfession,
    watchAddPostTag,
    watchAddLoanProvider,
    watchAddProfessionService,
    watchAddServiceLocation,
    watchGetPostTags,
    watchGetProfessions,
    watchGetProfessionsServices,
    watchGetServiceLocations,
    watchGetLoanProviders,
    watchGetLeads,
    watchGetReports,
    watchUpdateReportStatus,
    watchAddleadActivity,
    watchDeleteleadStatus,
    watchUpdateLeadStatus,
    watchGetInsight,
    watchCreateZone,
    watchGetZones,
    watchCreateLeadMasterActivity,
    watchGetLeadMasterActivity,
    watchGetLeadDetails,
    watchAddInventoryItem,
    watchGetInventoryItems,
    watchGetInventoryLeads,
    watchGetPdInsight,
    watchGetInquiries,
    watchShortenDeepLink,
    watchGetDeepLinks,
    watchAddBanner,
    watchGetBanners,
    watchGetTransactionService,
    watchUpsertTransactionService
} from './utilsSaga';

import {
    watchGetChats,
    watchGetMessages,
    watchClearChats,
    watchClearMessages
} from './chatSaga';
import { watchProjects } from './projectSaga';
import { watchTransactionServices } from './transactionServiceSaga';
import { watchReviews } from './reviewSaga';

export default function* rootSaga() {
    yield all([
        fork(watchGetUserPosts),
        fork(watchGetTransactionService),
        fork(watchUpsertTransactionService),
        fork(watchProjects),
        fork(watchTransactionServices),
        fork(watchReviews),
        fork(watchAddInventoryItem),
        fork(watchGetInventoryItems),
        fork(watchGetInventoryLeads),
        fork(watchGetTvPosts),
        fork(watchTvAddPost),
        fork(watchGetDiscussionPosts),
        fork(watchGetDiscussionComments),
        fork(watchChangeDiscussionStatus),
        fork(watchDiscussionComment),
        fork(watchDiscussionReact),
        fork(watchDiscussionReply),
        fork(watchGetDiscussionReplies),
        fork(watchEditScheduleNotification),
        fork(watchDeleteScheduleNotification),
        fork(watchGetPushNotifications),
        fork(watchCreateLeadMasterActivity),
        fork(watchGetLeadMasterActivity),
        fork(watchCreateZone),
        fork(watchGetZones),
        fork(watchRejectPost),
        fork(watchRejectDiscussion),
        fork(watchAddleadActivity),
        fork(watchDeleteleadStatus),
        fork(watchUpdateLeadStatus),
        fork(watchGetReports),
        fork(watchUpdateReportStatus),
        fork(watchGetChats),
        fork(watchGetMessages),
        fork(watchClearChats),
        fork(watchClearMessages),
        fork(watchGetInternalUsers),
        fork(watchGetUsersForAutoFollow),
        fork(watchStartFollowService),
        fork(watchGetInsight),
        fork(watchGetGridPosts),
        fork(watchGetAdmins),
        fork(watchResetAdminPassword),
        fork(watchSendFBNotification),
        fork(watchGetUsers),
        fork(watchCreateUser),
        fork(watchVerifyPost),
        fork(watchVerifyDiscussionPost),
        fork(watchGetDiscussions),
        fork(watchGetPosts),
        fork(watchGetLeads),
        fork(watchGetLeadDetails),
        fork(watchLogin),
        fork(watchGetLoanProviders),
        fork(watchLogout),
        fork(watchAddProfession),
        fork(watchAddPostTag),
        fork(watchAddLoanProvider),
        fork(watchAddProfessionService),
        fork(watchAddServiceLocation),
        fork(watchGetPostTags),
        fork(watchGetProfessions),
        fork(watchGetProfessionsServices),
        fork(watchGetServiceLocations),
        fork(watchGetReviewedPosts),
        fork(watchGetPdInsight),
        fork(watchGetInquiries),
        fork(watchShortenDeepLink),
        fork(watchGetDeepLinks),
        fork(watchAddBanner),
        fork(watchGetBanners),
        fork(watchGetUser),
        fork(watchUpsertProfessional),
    ]);
}
