import { put, takeEvery, call } from 'redux-saga/effects';
import {
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    GET_USERS_START,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAIL,
    CREATE_USER_START,
    GET_ADMINS_SUCCESS,
    GET_ADMINS_FAIL,
    RESET_ADMIN_PASSWORD_SUCCESS,
    RESET_ADMIN_PASSWORD_FAIL,
    RESET_ADMIN_PASSWORD_START,
    GET_ADMINS_START,
    GET_INTERNAL_USERS_SUCCESS,
    GET_INTERNAL_USERS_FAIL,
    GET_INTERNAL_USERS_START,
    GET_USERS_FOR_AUTO_FOLLOW_SUCCESS,
    GET_USERS_FOR_AUTO_FOLLOW_FAIL,
    GET_USERS_FOR_AUTO_FOLLOW_START,
    START_FOLLOW_SERVICE_SUCCESS,
    START_FOLLOW_SERVICE_FAIL,
    START_FOLLOW_SERVICE_START,
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    GET_USER_START,
    UPSERT_PROFESSIONAL_SUCCESS,
    UPSERT_PROFESSIONAL_FAIL,
    UPSERT_PROFESSIONAL_START
} from 'store/actions';

import Fetch from '../../network/Fetch';
import {
    GET_USERS, CREATE_USER, GET_ADMINS, RESET_ADMIN_PASSWORD, GET_USERS_FOR_AUTO_FOLLOW, START_FOLLOW_SERVICE,
    GET_USER,
    UPSERT_PROFESSIONAL
} from '../constant';


function* getUsers(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_USERS, params);
        if (response.status === 200) {
            yield put({ type: GET_USERS_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_USERS_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_USERS_FAIL });
    }
}

function* getUser(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_USER, params);
        if (response.status === 200) {
            yield put({ type: GET_USER_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_USER_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_USERS_FAIL });
    }
}

function* getUsersForAutoFollow(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_USERS_FOR_AUTO_FOLLOW, params);
        if (response.status === 200) {
            yield put({ type: GET_USERS_FOR_AUTO_FOLLOW_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_USERS_FOR_AUTO_FOLLOW_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_USERS_FOR_AUTO_FOLLOW_FAIL });
    }
}

function* getInternalUsers(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_USERS, params);
        if (response.status === 200) {
            yield put({ type: GET_INTERNAL_USERS_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_INTERNAL_USERS_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_INTERNAL_USERS_FAIL });
    }
}

function* createUser(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', CREATE_USER, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: CREATE_USER_SUCCESS });
        } else {
            action.callBack('error', response.message);
            yield put({ type: CREATE_USER_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: CREATE_USER_FAIL });
    }
}

function* upsertProfessional(action) {
    try {
        const params = action.params;
        const images = action.images;
        const response = yield call(new Fetch().axiosFormDataRequest, 'POST', UPSERT_PROFESSIONAL, params, images);
        if (response.status === 200) {
            action.callBack('success', response.data);
            yield put({ type: UPSERT_PROFESSIONAL_SUCCESS, data: response.data });
        } else {
            action.callBack('error', response.message);
            yield put({ type: UPSERT_PROFESSIONAL_FAIL });
        }
    } catch (err) {
        action.callBack('error', err.message);
        yield put({ type: UPSERT_PROFESSIONAL_FAIL });
    }
}


function* getAdmins(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_ADMINS, params);
        if (response.status === 200) {
            yield put({ type: GET_ADMINS_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_ADMINS_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_ADMINS_FAIL });
    }
}

function* resetAdminPassword(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', RESET_ADMIN_PASSWORD, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: RESET_ADMIN_PASSWORD_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: RESET_ADMIN_PASSWORD_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: RESET_ADMIN_PASSWORD_FAIL });
    }
}

function* startFollowService(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', START_FOLLOW_SERVICE, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: START_FOLLOW_SERVICE_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: START_FOLLOW_SERVICE_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: START_FOLLOW_SERVICE_FAIL });
    }
}

export function* watchGetUsers() {
    yield takeEvery(GET_USERS_START, getUsers);
}

export function* watchGetUser() {
    yield takeEvery(GET_USER_START, getUser);
}

export function* watchUpsertProfessional() {
    yield takeEvery(UPSERT_PROFESSIONAL_START, upsertProfessional);
}

export function* watchGetUsersForAutoFollow() {
    yield takeEvery(GET_USERS_FOR_AUTO_FOLLOW_START, getUsersForAutoFollow);
}

export function* watchGetInternalUsers() {
    yield takeEvery(GET_INTERNAL_USERS_START, getInternalUsers);
}

export function* watchCreateUser() {
    yield takeEvery(CREATE_USER_START, createUser);
}
export function* watchGetAdmins() {
    yield takeEvery(GET_ADMINS_START, getAdmins);
}

export function* watchResetAdminPassword() {
    yield takeEvery(RESET_ADMIN_PASSWORD_START, resetAdminPassword);
}

export function* watchStartFollowService() {
    yield takeEvery(START_FOLLOW_SERVICE_START, startFollowService);
}
