import { put, takeEvery, call } from 'redux-saga/effects';
import { AUTH_SUCCESS, AUTH_FAIL, LOGOUT_SUCCESS, LOGOUT_FAIL, AUTH_START, LOGOUT_START } from 'store/actions';
import Fetch from '../../network/Fetch';
import { ADMIN_LOGIN, UNREGISTER } from '../constant';

function* login(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', ADMIN_LOGIN, params);
        if (response.status === 200) {
            action.callback(null, response.data);
            yield put({ type: AUTH_SUCCESS });
        } else {
            action.callback({ message: response.message }, null);
            yield put({ type: AUTH_FAIL });
        }
    } catch (err) {
        action.callback(err, null);
        yield put({ type: AUTH_FAIL });
    }
}

function* logout(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', UNREGISTER);
        if (response.status === 200) {
            action.callback(null, response.data);
            yield put({ type: LOGOUT_SUCCESS });
        } else {
            action.callback({ message: response.Message }, null);
            yield put({ type: LOGOUT_FAIL });
        }
    } catch (err) {
        action.callback(err, null);
        yield put({ type: LOGOUT_FAIL });
    }
}

export function* watchLogin() {
    yield takeEvery(AUTH_START, login);
}

export function* watchLogout() {
    yield takeEvery(LOGOUT_START, logout);
}
