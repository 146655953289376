import pages from './pages';
import memberPages from './memberPages';
import leadManagementItem from './leadManagementItem';
import { getAdminRole } from 'utils/usersutils';


const getMenuItems = () => {
    const role = getAdminRole();
    switch (role) {
        case 1:
            return [pages];
        case 2:
            return [memberPages];
        case 3:
            return [memberPages];
        case 4:
            return [leadManagementItem];
        default:
            return [memberPages];
    }

}
const menuItems = {
    items: getMenuItems()
};

export default menuItems;
