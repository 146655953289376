import { put, takeEvery, call } from 'redux-saga/effects';
import Fetch from '../../network/Fetch';
import { GET_PROJECT, GET_PROJECTS, UPSERT_PROJECT } from 'store/constant';
import { GET_PROJECT_FAIL, GET_PROJECT_START, GET_PROJECT_SUCCESS, GET_PROJECTS_FAIL, GET_PROJECTS_START, GET_PROJECTS_SUCCESS, UPSERT_PROJECT_FAIL, UPSERT_PROJECT_START, UPSERT_PROJECT_SUCCESS } from 'store/actions';

function* getProjects(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_PROJECTS, action.params);
        if (response.status === 200) {
            yield put({ type: GET_PROJECTS_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_PROJECTS_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_PROJECTS_FAIL });
    }
}

function* getProject(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_PROJECT, action.params);
        if (response.status === 200) {
            yield put({ type: GET_PROJECT_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_PROJECT_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_PROJECT_FAIL });
    }
}

function* upsertProject(action) {
    try {
        const response = yield call(new Fetch().axiosFormDataRequest, 'POST', UPSERT_PROJECT, action.params, action.images);
        if (response.status === 200) {
            action.callBack('success', response.data);
            yield put({ type: UPSERT_PROJECT_SUCCESS, data: response.data });
        } else {
            action.callBack('error', response.message);
            yield put({ type: UPSERT_PROJECT_FAIL });
        }
    } catch (err) {
        action.callBack('error', err.message);
        yield put({ type: UPSERT_PROJECT_FAIL });
    }
}

export function* watchProjects() {
    yield takeEvery(GET_PROJECTS_START, getProjects);
    yield takeEvery(GET_PROJECT_START, getProject);
    yield takeEvery(UPSERT_PROJECT_START, upsertProject);
}