import * as ACTIONS from 'store/actions';

const initialState = {
    getDiscussionsLoading: false,
    discussions: [],
    discussionLoading: false,
    discussionCommentsLoading: false,
    discussionComments: [],
    userDiscussionLoading: false,
    userDiscussions: [],
    discussionReplies: [],
    discussionRepliesLoading: false
};

const discussionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_DISCUSSION_POSTS_START:
            return {
                ...state,
                getDiscussionsLoading: true
            };
        case ACTIONS.GET_DISCUSSION_POSTS_SUCCESS:
            return {
                ...state,
                getDiscussionsLoading: false,
                discussions: action.data
            };
        case ACTIONS.GET_DISCUSSION_POSTS_FAIL:
            return {
                ...state,
                getDiscussionsLoading: false
            };
        case ACTIONS.DISCUSSIONS_START:
            return {
                ...state,
                discussionLoading: true
            };
        case ACTIONS.DISCUSSIONS_SUCCESS:
            return {
                ...state,
                discussionLoading: false,
                discussions: action.data[0]?.page > 1
                    ? [...state.discussions, ...action.data]
                    : action.data,
            };
        case ACTIONS.DISCUSSIONS_FAIL:
            return {
                ...state,
                discussionLoading: false
            };
        case ACTIONS.DISCUSSION_COMMENTS_START:
            return {
                ...state,
                discussionCommentsLoading: true
            };
        case ACTIONS.DISCUSSION_COMMENTS_SUCCESS:
            return {
                ...state,
                discussionCommentsLoading: false,
                discussionComments: action.data[0]?.page > 1
                    ? [...state.discussionComments, ...action.data]
                    : action.data,
            };
        case ACTIONS.DISCUSSION_COMMENTS_FAIL:
            return {
                ...state,
                discussionCommentsLoading: false
            };
        case ACTIONS.ADD_DISCUSSION_COMMENT_START:
            return {
                ...state,
            };
        case ACTIONS.ADD_DISCUSSION_COMMENT_SUCCESS:
            const updateCommentDiscussions = state.discussions?.map((item) => {
                if (action.data.sourceID == item._id) {
                    return { ...item, commentCount: item.commentCount + 1 }
                } else {
                    return item;
                }
            })
            const updateCommentUserDiscussions = state.userDiscussions?.map((item) => {
                if (action.data.sourceID == item._id) {
                    return { ...item, commentCount: item.commentCount + 1 }
                } else {
                    return item;
                }
            })
            const cSourceID = state.discussionComments.length > 0 ? state.discussionComments[0].sourceID : null;
            return {
                ...state,
                discussions: updateCommentDiscussions,
                userDiscussions: updateCommentUserDiscussions,
                discussionComments: cSourceID == action.data.sourceID
                    ? [action.data, ...state.discussionComments]
                    : [action.data],
            };
        case ACTIONS.ADD_DISCUSSION_COMMENT_FAIL:
            return {
                ...state,
            };

        case ACTIONS.ADD_DISCUSSION_REACT_START:
            return {
                ...state,
            };
        case ACTIONS.ADD_DISCUSSION_REACT_SUCCESS:
            const sourceID = action.data.sourceID;
            const deleted = action.data.deleted;
            const newDiscussionssData = state.discussions?.map((item) => {
                if (sourceID == item._id) {
                    return { ...item, react: deleted ? null : action.data, reactCount: deleted ? (item.reactCount > 0 ? item.reactCount - 1 : 0) : item.reactCount + 1 }
                } else {
                    return item;
                }
            })
            const newUserDiscussionsData = state.userDiscussions?.map((item) => {
                if (sourceID == item._id) {
                    return { ...item, react: deleted ? null : action.data, reactCount: deleted ? (item.reactCount > 0 ? item.reactCount - 1 : 0) : item.reactCount + 1 }
                } else {
                    return item;
                }
            })
            return {
                ...state,
                discussions: newDiscussionssData,
                userDiscussions: newUserDiscussionsData
            };
        case ACTIONS.ADD_DISCUSSION_REACT_FAIL:
            return {
                ...state,
            };
        case ACTIONS.DISCUSSION_REPLIES_START:
            return {
                ...state,
                discussionRepliesLoading: true
            };
        case ACTIONS.DISCUSSION_REPLIES_SUCCESS:
            return {
                ...state,
                discussionRepliesLoading: false,
                discussionReplies:
                    action.data[0]?.page > 1
                        ? [...state.discussionReplies, ...action.data]
                        : action.data,
            };
        case ACTIONS.DISCUSSION_REPLIES_FAIL:
            return {
                ...state,
                discussionRepliesLoading: false
            };
        case ACTIONS.ADD_DISCUSSION_REPLY_START:
            return {
                ...state,
            };
        case ACTIONS.ADD_DISCUSSION_REPLY_SUCCESS:
            const updateComments = state.discussionComments?.map((item) => {
                if (action.data.sourceID == item._id) {
                    return { ...item, commentCount: item.commentCount + 1 }
                } else {
                    return item;
                }
            })
            const cCommentID = state.discussionReplies.length > 0 ? state.discussionReplies[0].sourceID : null;
            return {
                ...state,
                discussionComments: updateComments,
                discussionReplies: cCommentID == action.data.sourceID
                    ? [action.data, ...state.discussionReplies]
                    : [action.data],
            };
        case ACTIONS.ADD_DISCUSSION_REPLY_FAIL:
            return {
                ...state,
            };
        default:
            return state;
    }
};
export default discussionReducer;
