import { put, takeEvery, call } from 'redux-saga/effects';
import {
    SEND_FB_ALERT_SUCCESS,
    SEND_FB_ALERT_FAIL,
    SEND_FB_ALERT_START,
    GET_PUSH_NOTIFICATIONS_SUCCESS,
    GET_PUSH_NOTIFICATIONS_FAIL,
    GET_PUSH_NOTIFICATIONS_START,
    DELETE_SCHEDULED_NOTIFICATION_START,
    DELETE_SCHEDULED_NOTIFICATION_SUCCESS,
    DELETE_SCHEDULED_NOTIFICATION_FAIL,
    EDIT_SCHEDULED_NOTIFICATION_SUCCESS,
    EDIT_SCHEDULED_NOTIFICATION_FAIL,
    EDIT_SCHEDULED_NOTIFICATION_START
} from 'store/actions';

import Fetch from '../../network/Fetch';
import {
    GET_PUSH_NOTIFICATIONS,
    SEND_FB_ALERT,
    DELETE_SCHEDULED_NOTIFICATION,
    EDIT_SCHEDULED_NOTIFICATION
} from '../constant';


function* getPushNotifications(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_PUSH_NOTIFICATIONS, params);
        if (response.status === 200) {
            yield put({ type: GET_PUSH_NOTIFICATIONS_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_PUSH_NOTIFICATIONS_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_PUSH_NOTIFICATIONS_FAIL });
    }
}

function* sendFBNotification(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', SEND_FB_ALERT, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: SEND_FB_ALERT_SUCCESS });
        } else {
            action.callBack('error', response.message || 'No firenase token found! Try different filter.');
            yield put({ type: SEND_FB_ALERT_FAIL });
        }
    } catch (err) {
        action.callBack('error', err.message || 'No firenase token found! Try different filter.');
        yield put({ type: SEND_FB_ALERT_FAIL });
    }
}

function* deleteScheduleNotification(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', DELETE_SCHEDULED_NOTIFICATION, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: DELETE_SCHEDULED_NOTIFICATION_SUCCESS });
        } else {
            action.callBack('error', response.message || 'No firenase token found! Try different filter.');
            yield put({ type: DELETE_SCHEDULED_NOTIFICATION_FAIL });
        }
    } catch (err) {
        action.callBack('error', err.message || 'No firenase token found! Try different filter.');
        yield put({ type: DELETE_SCHEDULED_NOTIFICATION_FAIL });
    }
}

function* editScheduleNotification(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', EDIT_SCHEDULED_NOTIFICATION, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: EDIT_SCHEDULED_NOTIFICATION_SUCCESS });
        } else {
            action.callBack('error', response.message || 'No firenase token found! Try different filter.');
            yield put({ type: EDIT_SCHEDULED_NOTIFICATION_FAIL });
        }
    } catch (err) {
        action.callBack('error', err.message || 'No firenase token found! Try different filter.');
        yield put({ type: EDIT_SCHEDULED_NOTIFICATION_FAIL });
    }
}

export function* watchGetPushNotifications() {
    yield takeEvery(GET_PUSH_NOTIFICATIONS_START, getPushNotifications);
}

export function* watchSendFBNotification() {
    yield takeEvery(SEND_FB_ALERT_START, sendFBNotification);
}

export function* watchDeleteScheduleNotification() {
    yield takeEvery(DELETE_SCHEDULED_NOTIFICATION_START, deleteScheduleNotification);
}
export function* watchEditScheduleNotification() {
    yield takeEvery(EDIT_SCHEDULED_NOTIFICATION_START, editScheduleNotification);
}