import { put, takeEvery, call } from 'redux-saga/effects';
import * as ACTION from 'store/actions';

import Fetch from '../../network/Fetch';
import * as Constant from '../constant';


function* getDiscussions(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_DISCUSSION_POSTS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_DISCUSSION_POSTS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_DISCUSSION_POSTS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_DISCUSSION_POSTS_FAIL });
    }
}

function* verifyPost(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.VERIFY_DISCUSSION_POST, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.VERIFY_DISCUSSION_POST_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.VERIFY_DISCUSSION_POST_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.VERIFY_DISCUSSION_POST_FAIL });
    }
}

function* rejectDiscussion(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.DISCUSSION_POST_REJECT, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.DISCUSSION_POST_REJECT_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.DISCUSSION_POST_REJECT_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.DISCUSSION_POST_REJECT_FAIL });
    }
}



function* changeDiscussionStatus(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.CHANGE_DISCUSSION_STATUS, params);
        if (response.status === 200) {
            action.callBack('success', 'Discussion deleted');
            yield put({ type: ACTION.CHANGE_DISCUSSION_STATUS_SUCCESS, data: response.data });
        } else {
            action.callBack('error', 'Something went wrong.');
            yield put({ type: ACTION.CHANGE_DISCUSSION_STATUS_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Something went wrong.');
        yield put({ type: ACTION.CHANGE_DISCUSSION_STATUS_FAIL });
    }
}


function* getDiscussionPosts(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_DISCUSSIONS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.DISCUSSIONS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.DISCUSSIONS_FAIL });
        }
    } catch (err) {
        action.callback(err, null);
        yield put({ type: ACTION.DISCUSSIONS_FAIL });
    }
}

function* getDiscussionComments(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_DISCUSSION_COMMENTS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.DISCUSSION_COMMENTS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.DISCUSSION_COMMENTS_FAIL });
        }
    } catch (err) {
        action.callback(err, null);
        yield put({ type: ACTION.DISCUSSION_COMMENTS_FAIL });
    }
}

function* getDiscussionReplies(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_DISCUSSION_COMMENTS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.DISCUSSION_REPLIES_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.DISCUSSION_REPLIES_FAIL });
        }
    } catch (err) {
        action.callback(err, null);
        yield put({ type: ACTION.DISCUSSION_REPLIES_FAIL });
    }
}
function* comment(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_DISCUSSION_COMMENT, params);
        if (response.status === 200) {
            yield put({ type: ACTION.ADD_DISCUSSION_COMMENT_SUCCESS, data: response.data });
        } else {
            action.callback({ message: response.message }, null);
            yield put({ type: ACTION.ADD_DISCUSSION_COMMENT_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.ADD_DISCUSSION_COMMENT_FAIL });
    }
}

function* react(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_DISCUSSION_REACT, params);
        if (response.status === 200) {
            action.callBack(response.data);
            yield put({ type: ACTION.ADD_DISCUSSION_REACT_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.ADD_DISCUSSION_REACT_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.ADD_DISCUSSION_REACT_FAIL });
    }
}

function* reply(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_DISCUSSION_REPLY, params);
        if (response.status === 200) {
            action.callBack(response.data);
            yield put({ type: ACTION.ADD_DISCUSSION_REPLY_SUCCESS, data: response.data });
        } else {
            action.callBack();
            yield put({ type: ACTION.ADD_DISCUSSION_REPLY_FAIL });
        }
    } catch (err) {
        action.callBack();
        yield put({ type: ACTION.ADD_DISCUSSION_REPLY_FAIL });
    }
}
export function* watchChangeDiscussionStatus() {
    yield takeEvery(ACTION.CHANGE_DISCUSSION_STATUS_START, changeDiscussionStatus);
}

export function* watchGetDiscussionPosts() {
    yield takeEvery(ACTION.DISCUSSIONS_START, getDiscussionPosts);
}

export function* watchGetDiscussionComments() {
    yield takeEvery(ACTION.DISCUSSION_COMMENTS_START, getDiscussionComments);
}

export function* watchGetDiscussionReplies() {
    yield takeEvery(ACTION.DISCUSSION_REPLIES_START, getDiscussionReplies);
}

export function* watchDiscussionReply() {
    yield takeEvery(ACTION.ADD_DISCUSSION_REPLY_START, reply);
}
export function* watchDiscussionComment() {
    yield takeEvery(ACTION.ADD_DISCUSSION_COMMENT_START, comment);
}

export function* watchDiscussionReact() {
    yield takeEvery(ACTION.ADD_DISCUSSION_REACT_START, react);
}


export function* watchGetDiscussions() {
    yield takeEvery(ACTION.GET_DISCUSSION_POSTS_START, getDiscussions);
}

export function* watchVerifyDiscussionPost() {
    yield takeEvery(ACTION.VERIFY_DISCUSSION_POST_START, verifyPost);
}

export function* watchRejectDiscussion() {
    yield takeEvery(ACTION.DISCUSSION_POST_REJECT_START, rejectDiscussion);
}