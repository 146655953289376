import * as ACTIONS from 'store/actions';

const initialState = {
    logoutLoading: false,
    loading: false,
    loggedIn: false,
    userData: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.AUTH_START:
            return {
                ...state,
                loading: true
            };
        case ACTIONS.AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.data,
                loggedIn: true
            };
        case ACTIONS.AUTH_FAIL:
            return {
                ...state,
                loading: false
            };
        case ACTIONS.LOGOUT_START:
            return {
                ...state,
                logoutLoading: true
            };
        case ACTIONS.LOGOUT_SUCCESS:
            return {
                ...state,
                logoutLoading: false
            };
        case ACTIONS.LOGOUT_FAIL:
            return {
                ...state,
                logoutLoading: false
            };
        default:
            return state;
    }
};
export default authReducer;
