import * as ACTIONS from 'store/actions';

const initialState = {
    getPostsLoading: false,
    posts: [],
};

const tvPostReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_TV_POSTS_START:
            return {
                ...state,
                getPostsLoading: true
            };
        case ACTIONS.GET_TV_POSTS_SUCCESS:
            return {
                ...state,
                getPostsLoading: false,
                posts: action.data
            };
        case ACTIONS.GET_TV_POSTS_FAIL:
            return {
                ...state,
                getPostsLoading: false
            };
        default:
            return state;
    }
};
export default tvPostReducer;
