import Fetch from "network/Fetch";
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_USER_TRANSACTIONS_SERVICE_FAIL, GET_USER_TRANSACTIONS_SERVICE_START, GET_USER_TRANSACTIONS_SERVICE_SUCCESS, GET_USER_TRANSACTION_SERVICES_FAIL, GET_USER_TRANSACTION_SERVICES_START, GET_USER_TRANSACTION_SERVICES_SUCCESS, UPSERT_USER_TRANSACTION_SERVICE_FAIL, UPSERT_USER_TRANSACTION_SERVICE_START, UPSERT_USER_TRANSACTION_SERVICE_SUCCESS } from "store/actions";
import { GET_USER_TRANSACTIONS_SERVICE, GET_USER_TRANSACTION_SERVICES, UPSERT_USER_TRANSACTION_SERVICE } from "store/constant";

function* getTransactionServices(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_USER_TRANSACTION_SERVICES, action.params);
        if (response.status === 200) {
            yield put({ type: GET_USER_TRANSACTION_SERVICES_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_USER_TRANSACTION_SERVICES_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_USER_TRANSACTION_SERVICES_FAIL });
    }
}

function* getTransactionService(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_USER_TRANSACTIONS_SERVICE, action.params);
        if (response.status === 200) {
            yield put({ type: GET_USER_TRANSACTIONS_SERVICE_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_USER_TRANSACTIONS_SERVICE_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_USER_TRANSACTIONS_SERVICE_FAIL });
    }
}

function* upsertTransactionService(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', UPSERT_USER_TRANSACTION_SERVICE, action.params);
        if (response.status === 200) {
            action.callBack('success', response.data);
            yield put({ type: UPSERT_USER_TRANSACTION_SERVICE_SUCCESS, data: response.data });
        } else {
            action.callBack('error', response.message);
            yield put({ type: UPSERT_USER_TRANSACTION_SERVICE_FAIL });
        }
    } catch (err) {
        action.callBack('error', err.message);
        yield put({ type: UPSERT_USER_TRANSACTION_SERVICE_FAIL });
    }
}

export function* watchTransactionServices() {
    yield takeEvery(GET_USER_TRANSACTION_SERVICES_START, getTransactionServices);
    yield takeEvery(GET_USER_TRANSACTIONS_SERVICE_START, getTransactionService);
    yield takeEvery(UPSERT_USER_TRANSACTION_SERVICE_START, upsertTransactionService);
}