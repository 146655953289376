import * as ACTIONS from 'store/actions';

const initialState = {
    listLoading: false,
    singleLoading: false,
    count: 0,
    list: [],
    single: {},
};

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_PROJECTS_START:
            return {
                ...state,
                listLoading: true,
            };
        case ACTIONS.GET_PROJECTS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                list: action.data.list,
                count: action.data.count,
            };
        case ACTIONS.GET_PROJECTS_FAIL:
            return {
                ...state,
                listLoading: false,
            };
        case ACTIONS.GET_PROJECT_START:
            return {
                ...state,
                singleLoading: true,
            };
        case ACTIONS.GET_PROJECT_SUCCESS:
            return {
                ...state,
                singleLoading: false,
                single: action.data,
            };
        case ACTIONS.GET_PROJECT_FAIL:
            return {
                ...state,
                singleLoading: false,
            };
        case ACTIONS.UPSERT_PROJECT_START:
            return {
                ...state,
            };
        case ACTIONS.UPSERT_PROJECT_SUCCESS:
            const itemIndex = state.list.findIndex(listItem => listItem._id === action.data._id);
            return {
                ...state,
                list: itemIndex !== -1
                    ? [
                        ...state.list.slice(0, itemIndex),
                        {
                            ...state.list[itemIndex],
                            rating: action.data.rating,
                            review: action.data.review,
                            anonymous: action.data.anonymous,
                            phone: action.data.phone,
                        },
                        ...state.list.slice(itemIndex + 1),
                    ]
                    : [action.data, ...state.list],
            };
        case ACTIONS.UPSERT_PROJECT_FAIL:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default projectReducer;