import { put, takeEvery, call } from 'redux-saga/effects';
import * as ACTION from 'store/actions';

import Fetch from '../../network/Fetch';
import * as Constant from '../constant';


function* getPosts(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_POSTS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_POSTS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_POSTS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_POSTS_FAIL });
    }
}

function* getUserPosts(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_USER_POSTS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_USER_POSTS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_USER_POSTS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_USER_POSTS_FAIL });
    }
}

function* getGridPosts(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET',Constant.GET_POSTS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_POSTS_GRID_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_POSTS_GRID_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_POSTS_GRID_FAIL });
    }
}

function* getReviewedPosts(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET',Constant.GET_REVIEWED_POSTS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_REVIEWED_POSTS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_REVIEWED_POSTS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_REVIEWED_POSTS_FAIL });
    }
}

function* verifyPost(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.VERIFY_POST, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.VERIFY_POST_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.VERIFY_POST_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.VERIFY_POST_FAIL });
    }
}

function* rejectPost(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.POST_REJECT, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.POST_REJECT_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.POST_REJECT_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.POST_REJECT_FAIL });
    }
}

export function* watchGetPosts() {
    yield takeEvery(ACTION.GET_POSTS_START, getPosts);
}
export function* watchGetUserPosts() {
    yield takeEvery(ACTION.GET_USER_POSTS_START, getUserPosts);
}

export function* watchGetReviewedPosts() {
    yield takeEvery(ACTION.GET_REVIEWED_POSTS_START, getReviewedPosts);
}

export function* watchGetGridPosts() {
    yield takeEvery(ACTION.GET_POSTS_GRID_START, getGridPosts);
}

export function* watchVerifyPost() {
    yield takeEvery(ACTION.VERIFY_POST_START, verifyPost);
}

export function* watchRejectPost() {
    yield takeEvery(ACTION.POST_REJECT_START, rejectPost);
}