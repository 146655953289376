import { put, takeEvery, call } from 'redux-saga/effects';
import * as ACTION from 'store/actions';

import Fetch from '../../network/Fetch';
import * as Constant from '../constant';


function* getPosts(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_TV_POSTS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_TV_POSTS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_TV_POSTS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_TV_POSTS_FAIL });
    }
}


function* addPost(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_TV_POST, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.ADD_TV_POST_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.ADD_TV_POST_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.ADD_TV_POST_FAIL });
    }
}


export function* watchGetTvPosts() {
    yield takeEvery(ACTION.GET_TV_POSTS_START, getPosts);
}

export function* watchTvAddPost() {
    yield takeEvery(ACTION.ADD_TV_POST_START, addPost);
}
