import React from 'react'
import { styles } from './styles';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import {
  IconSettings, IconUserCircle,
  IconLogout
} from '@tabler/icons';
import { useNavigate } from 'react-router-dom';

export default function Profile({ anchorEl, openProfile,
  handleCloseProfile }) {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.setItem('admin', '');
    navigate(`/`);
  }


  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        sx={{ zIndex: 100002 }}
        open={openProfile}
        onClose={handleCloseProfile}
        onClick={handleCloseProfile}
        PaperProps={styles.box}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <MenuItem >
          <ListItemIcon>
            <IconUserCircle />
          </ListItemIcon>
          Profile
        </MenuItem>
        <Divider />

        <MenuItem>
          <ListItemIcon>
            <IconSettings />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <IconLogout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}
