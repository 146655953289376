// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import memberPages from 'menuItems/memberPages';
import leadManagementItem from 'menuItems/leadManagementItem';
import pages from 'menuItems/pages';
import { getAdminRole } from 'utils/usersutils';

const getMenuItems = () => {
    const role = getAdminRole();
    switch (role) {
        case 1:
            return [pages];
        case 2:
            return [memberPages];
        case 3:
            return [memberPages];
        case 4:
            return [leadManagementItem];
        default:
            return [memberPages];
    }

}
const MenuList = () => {
    const menuItems = getMenuItems();
    const navItems = menuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
